import React from 'react';
import './TemplateSection.css'


const TemplateSection = ({template, handleChangeTemplate, addInput, deleteInput}) => {

  return (
    <>
      {/* <div className="card-payku"> */}
      <div className="card-body-payku">
        <div className="header-payku">
          <h1 className="tittle-form-sub">Plantillas de Entradas</h1>
        </div>
        {template &&
          template.map((input, index) => (
            <>
              <div className="template-section" key={index}>
                <div className="template-item">
                  <label htmlFor={`order-${index}`}>
                    {`Orden`} <span>Oblig</span>
                  </label>
                  <input
                    type="text"
                    name={`order-${index}`}
                    placeholder="Número de orden"
                    data-id={index}
                    id={`order-${index}`}
                    data-name="order"
                    value={input.order}
                    onChange={handleChangeTemplate}
                  />
                </div>

                <div className="template-item">
                  <label htmlFor={`label-${index}`}>
                    {`Label`} <span>Oblig</span>
                  </label>
                  <input
                    type="text"
                    name={`label-${index}`}
                    placeholder="Label"
                    data-id={index}
                    id={`label-${index}`}
                    data-name="label"
                    value={input.label}
                    onChange={handleChangeTemplate}
                  />
                </div>

                <div className="template-item">
                  <label htmlFor={`name-${index}`}>
                    {`Name`} <span>Oblig</span>
                  </label>
                  <input
                    type="text"
                    name={`name-${index}`}
                    placeholder="Nombre"
                    data-id={index}
                    id={`name-${index}`}
                    value={input.name}
                    data-name="name"
                    onChange={handleChangeTemplate}
                  />
                </div>

                <div className="template-item">
                  <label htmlFor={`type-${index}`}>{`Tipo`}</label>
                  <select
                    name={`type-${index}`}
                    data-id={index}
                    id={`type-${index}`}
                    value={input.type}
                    data-name="type"
                    onChange={handleChangeTemplate}
                  >
                    <option value="text">Texto</option>
                    <option value="phone">Telefono</option>
                    <option value="select">Selector</option>
                    <option value="textbox">Text Box</option>
                    <option value="checkbox">Check Box</option>
                    <option value="date">Fecha</option>
                  </select>
                </div>

                {input.type === "select" ? (
                  <div className="template-item">
                    <label htmlFor={`options-${index}`}>{`Opciones`}</label>
                    <input
                      type="text"
                      name={`options-${index}`}
                      data-id={index}
                      id={`options-${index}`}
                      value={input.options.toString()}
                      data-name="options"
                      onChange={handleChangeTemplate}
                    />
                  </div>
                ) : null}

                <div className="template-item">
                  <label htmlFor={`require-${index}`}>{`Requerido`}</label>
                  <select
                    name={`require-${index}`}
                    data-id={index}
                    id={`require-${index}`}
                    value={input.require}
                    data-name="require"
                    onChange={handleChangeTemplate}
                  >
                    <option value={false}>No</option>
                    <option value={true}>Si</option>
                  </select>
                </div>

                <div className="template-item last">
                  <div className="template-button">
                    <button
                      className="btn-payku btn-danger-payku"
                      onClick={() => deleteInput(index)}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
              <hr className="separator-line" />
            </>
          ))}
        <div className="container-button">
          <button className="btn-payku btn-primary-payku" onClick={addInput}>
            Agregar input +
          </button>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default TemplateSection;