/** @jsxRuntime classic */
/** @jsx jsx */
/**@jsxFrag React.Fragment */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { validate, format } from "rut.js";
import { css, jsx } from "@emotion/core";
import { firebaseContext } from "../../firebase";
import InputCreator from "../InputCreator/InputCreator";
import SelectSubscription from "../selectSubscription/SelectSubscription";
import CheckBoxCreator from "../checkBoxCreator/CheckBoxCreator";
import InfoPanel from "../infoPanel/InfoPanel";
import imagen from "../../img/LegadoChile.jpg";
import TextAreaCreator from "../textAreaCreator/TextAreaCreator";
import InvoiceComponent from "../invoiceComponent/InvoiceComponent";
import "./Form.css";

const Form = () => {
  const [inputs, setInputs] = useState();

  const [formData, setFormData] = useState({});

  const [otherCost, setOtherCost] = useState(false);

  const [errors, setErrors] = useState({});

  const { firebase } = useContext(firebaseContext);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeDate = (name, value) => {
    let event = new Date(value);
    let date = JSON.stringify(event);
    date = date.slice(1, 11);
    setFormData({
      ...formData,
      [name]: date,
    });
  };

  const handleChangeSubsription = (e) => {
    let newData = { ...formData };
    delete newData.monto;
    setFormData({
      ...newData,
      [e.target.name]: e.target.value,
    });
    setOtherCost(false);
  };

  const handleChangeSubsriptionOther = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      monto: "",
    });
    setOtherCost(true);
  };

  const handleCheckBox = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleClick = (value) => {
    setFormData({
      ...formData,
      subscriptionType: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};

    for (let [index, [key, value]] of Object.entries(
      Object.entries(formData)
    )) {
      if (key === "rutfactura" || key === "razonsocial") {
        if (key === "razonsocial" && value === "") {
          errors[key] = `El campo razon social es obligatorio`;
        }

        if (key === "rutfactura" && !validate(format(value))) {
          errors.rutfactura = "El Rut no es valido";
        }

        if (key === "rutfactura" && value === "") {
          errors.rutfactura = "El campo Rut es obligatorio";
        }
      } else {
        if (key !== "monto") {
          if (key !== "planId") {
            if (key !== "subscriptionType" && inputs.template[index].require) {
              // console.log(key);
              if (key === "confirmeEmail" && value !== formData.email) {
                errors.confirmeEmail = "El email no coincide";
              }

              if (
                key === "email" &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
              ) {
                errors.email = "El email no es valido";
              }

              if (key === "rut" && !validate(format(value))) {
                errors.rut = "El Rut no es valido";
              }

              if (key === "telefono" && value.length !== 8) {
                errors.telefono = "Debe tener 8 dígitos";
              }

              if (key === "telefono" && !/^([0-9])*$/.test(value)) {
                errors.telefono = "Número no válido";
              }

              if (value === "") {
                errors[key] = `El campo ${key} es obligatorio`;
              }

              if (key === "confirmeEmail" && value === "") {
                errors.confirmeEmail =
                  "El campo confirme E-mail es obligatorio";
              }
            }
          }
        } else {
          if (key === "monto" && !/^([0-9])*$/.test(value)) {
            errors.monto = "El valor del monto debe ser numerico";
          }

          if (key === "monto" && /^([0-9])*$/.test(value)) {
            const monto = Number(value);
            if (monto > limitMount.maxMount || monto < limitMount.minMount) {
              errors.monto = `El valor del monto debe estar entre $${limitMount.minMount} y $${limitMount.maxMount}`;
            }
          }
        }
      }

      if (key === "subscriptionType" && value === "") {
        errors.subscriptionType = "Seleccione alguna suscripción";
      }
    }
    setErrors(errors);

    const noErrors = Object.keys(errors).length === 0;

    if (noErrors) {
      let urlPart = [
        "Diario",
        "Semanal",
        "Mensual",
        "Bimensual",
        "Trimestral",
        "Anual",
      ].includes(formData.subscriptionType)
        ? "suscripcion"
        : "botonpago";

      let date = formData["fecha de nacimiento"]
        ? formData["fecha de nacimiento"]
        : "undefine";

      window.top.location.replace(eval("`" + inputs.paykuUrl + "`"));
    }
  };

  const { id } = useParams();

  useEffect(() => {
    const getForm = () => {
      firebase.db.collection("forms").onSnapshot(handleData);
    };
    getForm();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { template } = inputs || {};
    let initialState = {};
    template &&
      template.map((input) => {
        if (input.type === "checkbox") {
          initialState = { ...initialState, [input.name]: false };
        } else {
          initialState = { ...initialState, [input.name]: "" };
        }
        return initialState;
      });
    initialState = { ...initialState, subscriptionType: "" };
    setFormData(initialState);
  }, [inputs]);

  function handleData(snapshot) {
    const forms = snapshot.docs.filter((doc) => doc.id === id);
    setInputs(forms[0].data());
  }

  const {
    infoPanelEnable,
    theme,
    htmlInfo,
    panelText,
    title,
    media,
    headerEnableForm,
    headerEnablePanelInfo,
    cardBackground,
    limitMount,
    invoice,
  } = inputs || {};
  const {
    backgroundBody,
    button,
    header,
    inputTheme,
    swapColumns,
    labelAlign,
    inputColumns,
  } = theme || {};
  const {
    background,
    textColor,
    backgroundHover,
    textColorHover,
    submitText,
    borderColor,
  } = button || {};
  const {
    backgroundHeader,
    textColorHeader,
    urlBusinessLogo,
    logoBusinessEnableForm,
    logoBusinessEnableInfoPanel,
    logoBusinessHeigth,
    logoBusinessWidth,
  } = header || {};
  const {
    iBorderColor,
    iBorderColorFocus,
    shadowBoxB,
    shadowBoxG,
    shadowBoxR,
  } = inputTheme || {};

  const { imageEnable, imageHeight, imageUrl } = cardBackground || {};

  const backgroundBodyColor = css`
    background: ${backgroundBody};
    height: 100vh;
  `;

  const buttonTheme = css`
     {
      background: ${background};
      color: ${textColor};
      border: 0.1rem solid;
      border-color: ${borderColor};
      &:hover {
        background: ${backgroundHover};
        color: ${textColorHover};
        border-color: ${backgroundHover};
      }
    }
  `;

  const headerTheme = css`
     {
      background: ${backgroundHeader};
      color: ${textColorHeader};
    }
  `;

  const focusInput = css`
     {
      border-color: ${iBorderColor};
      &:focus {
        color: #159753;
        background-color: #fff;
        border-color: ${iBorderColorFocus};
        outline: 0;
        box-shadow: 0 0 0 0.2rem
          rgba(${shadowBoxR}, ${shadowBoxG}, ${shadowBoxB}, 0.25);
      }
    }
  `;

  const logoBusinessSize = css`
     {
      width: ${logoBusinessWidth};
      heigth: ${logoBusinessHeigth};
    }
  `;

  const cardImage = css`
     {
      background-image: url(${imagen});
      webkit-background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      -o-background-size: 100% 100%;
      background-size: 100% 100%;
    }
  `;

  const divSize = css`
     {
      height: ${imageHeight};
    }
  `;

  return (
    <div css={backgroundBodyColor}>
      <div className="row mx-auto">
        <div className={swapColumns ? "col-md-6 order-last" : "col-md-6"}>
          <div className="card my-3 panelSize">
            {logoBusinessEnableForm ? (
              <img src={urlBusinessLogo} alt="logo" css={logoBusinessSize} />
            ) : headerEnableForm ? (
              <h5 className="card-header text-center py-4" css={headerTheme}>
                <strong>{inputs && inputs.businessName}</strong>
              </h5>
            ) : null}
            <div className="card-body px-lg-5">
              <form className="text-center" onSubmit={handleSubmit}>
                <SelectSubscription
                  inputs={inputs}
                  errors={errors}
                  handleChangeSubsription={handleChangeSubsription}
                  handleChangeSubsriptionOther={handleChangeSubsriptionOther}
                  handleClick={handleClick}
                />
                <div className="row">
                  <InputCreator
                    inputs={inputs}
                    errors={errors}
                    labelAlign={labelAlign}
                    handleChange={handleChange}
                    otherCost={otherCost}
                    inputColumns={inputColumns}
                    focusInput={focusInput}
                    handleChangeDate={handleChangeDate}
                  />

                  <div
                    className={
                      inputColumns
                        ? `md-form mt-3 ${labelAlign} col-md-6`
                        : `md-form mt-3 ${labelAlign} col-md-12`
                    }
                  ></div>
                </div>

                <TextAreaCreator
                  inputs={inputs}
                  errors={errors}
                  labelAlign={labelAlign}
                  handleChange={handleChange}
                  focusInput={focusInput}
                />

                <CheckBoxCreator
                  inputs={inputs}
                  handleCheckBox={handleCheckBox}
                />

                {invoice ? (
                  <InvoiceComponent
                    inputs={inputs}
                    errors={errors}
                    labelAlign={labelAlign}
                    handleChange={handleChange}
                    focusInput={focusInput}
                    inputColumns={inputColumns}
                    setFormData={setFormData}
                    formData={formData}
                  />
                ) : null}

                <button
                  className={`btn btn-block mt-4`}
                  css={buttonTheme}
                  type="submit"
                >
                  {submitText}
                </button>
              </form>
            </div>
          </div>
        </div>
        {infoPanelEnable ? (
          <div className="col-md-6">
            <InfoPanel
              urlBusinessLogo={urlBusinessLogo}
              logoBusinessEnableInfoPanel={logoBusinessEnableInfoPanel}
              title={title}
              headerTheme={headerTheme}
              panelText={panelText}
              media={media}
              headerEnablePanelInfo={headerEnablePanelInfo}
              cardImage={cardImage}
              imageEnable={imageEnable}
              divSize={divSize}
              logoBusinessSize={logoBusinessSize}
              htmlInfo={htmlInfo}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Form;
