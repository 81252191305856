/** @jsxRuntime classic */
/** @jsx jsx */
/**@jsxFrag React.Fragment */
import React from "react";
import { jsx } from "@emotion/core";
import "./PhoneComponent.css";

const InputComponent = ({
  input,
  errors,
  handleChange,
  focusInput,
  inputColumns,
}) => {
  return (
    <>
      <label htmlFor={input.name}>{input.label}</label>
      <div className="phone-input">
        <span className={inputColumns ? `code code-small` : `code code-large`}>
          +56 9
        </span>
        <input
          className={
            inputColumns
              ? `form-control form-control-small`
              : `form-control form-control-large`
          }
          type="text"
          name={input.name}
          onChange={handleChange}
          placeholder=""
          css={focusInput}
        />
      </div>
      {errors[input.name] && <p className="error">{errors[input.name]}</p>}
    </>
  );
};

export default InputComponent;
