/** @jsxRuntime classic */
/** @jsx jsx */
/**@jsxFrag React.Fragment */
import React from "react";
import InputComponent from "../inputComponent/InputComponent";
import PhoneComponent from "../phoneComponent/PhoneComponent";
import SelectComponent from "../selectComponent/SelectComponent";
import DateInput from "../dateInput/DateInput";
import { jsx } from "@emotion/core";
import './InputCreator.css'

const InputCreator = ({
  inputs,
  errors,
  handleChange,
  otherCost,
  labelAlign,
  inputColumns,
  focusInput,
  handleChangeDate
}) => {
  return (
    <>
      {inputs &&
        inputs.template
          .filter(
            (input) =>
              input.type === "text" ||
              input.type === "select" ||
              input.type === "date" ||
              input.type === "phone"
          )
          .sort((inputA, inputB) => inputA.order - inputB.order)
          .map((input, index) => (
            <div
              className={
                inputColumns
                  ? `md-form mt-3 ${labelAlign} col-md-6`
                  : `md-form mt-3 ${labelAlign} col-md-12`
              }
              key={`${input.type}-${index}`}
            >
              {input.type === "text" || input.type === "email" ? (
                <InputComponent
                  input={input}
                  handleChange={handleChange}
                  focusInput={focusInput}
                  errors={errors}
                />
              ) : input.type === "select" ? (
                <SelectComponent
                  input={input}
                  handleChange={handleChange}
                  focusInput={focusInput}
                  errors={errors}
                />
              ) : input.type === "date" ? (
                <DateInput
                input={input}
                errors={errors}
                focusInput={focusInput}
                handleChangeDate={handleChangeDate}
                />
              ) : input.type === "phone" ? (
                <PhoneComponent
                  input={input}
                  handleChange={handleChange}
                  focusInput={focusInput}
                  errors={errors}
                  inputColumns={inputColumns}
                />
              ) : null}
            </div>
          ))}
      {otherCost === true ? (
        <div
          className={
            inputColumns
              ? `md-form mt-3 ${labelAlign} col-md-6`
              : `md-form mt-3 ${labelAlign} col-md-12`
          }
        >
          <label htmlFor="cost">Monto:</label>
          <input
            type="text"
            name="monto"
            onChange={handleChange}
            className="form-control"
            css={focusInput}
          />
          {errors.monto && <p className="error">{errors.monto}</p>}
        </div>
      ) : null}
    </>
  );
};

export default InputCreator;
