/** @jsxRuntime classic */
/** @jsx jsx */
/**@jsxFrag React.Fragment */
import React from "react";
import { jsx } from "@emotion/core";
import "./InputComponent.css";

const InputComponent = ({ input, errors, handleChange, focusInput }) => {
  return (
    <>
      <label htmlFor={input.name}>{input.label}</label>
      <input
        type={input.type}
        name={input.name}
        onChange={handleChange}
        placeholder={`Ingrese ${input.label.toLowerCase().replace(":", "")}`}
        className="form-control"
        css={focusInput}
      />
      {errors[input.name] && <p className="error">{errors[input.name]}</p>}
    </>
  );
};

export default InputComponent;
