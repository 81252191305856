
/** @jsxRuntime classic */
/** @jsx jsx */
/**@jsxFrag React.Fragment */
import React from "react";
import { jsx } from "@emotion/core";
import "./TextAreaComponent.css";

const TextAreaComponent = ({ input, errors, handleChange, focusInput }) => {
  return (
    <>
      <label htmlFor={input.name}>{input.label}</label>
      <textarea
        name={input.name}
        className="form-control"
        rows={input.rows}
        cols={input.cols}
        onChange={handleChange}
        css={focusInput}
      />
      {errors[input.name] && <p className="error">{errors[input.name]}</p>}
    </>
  );
};

export default TextAreaComponent;
