const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRABASE_apiKey,
  authDomain: process.env.REACT_APP_FIRABASE_authDomain,
  databaseURL: process.env.REACT_APP_FIRABASE_databaseURL,
  projectId: process.env.REACT_APP_FIRABASE_projectId,
  storageBucket: process.env.REACT_APP_FIRABASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIRABASE_messagingSenderId,
  appId: process.env.REACT_APP_FIRABASE_appId,
  measurementId: process.env.REACT_APP_FIRABASE_measurementId,
};
export default firebaseConfig;
