import React, { useState } from 'react';

const InvoiceComponent = ({ inputColumns, labelAlign, focusInput, errors, handleChange, setFormData, formData }) => {
  const [invoice, setInvoice] = useState(false);

  const handleCheckBox = (e) => {
    setInvoice(e.target.checked);
    if(e.target.checked) {
      setFormData({...formData, razonsocial: "", rutfactura: ""})
    } else {
      let newData = { ...formData };
    delete newData.razonsocial;
    delete newData.rutfactura;
    setFormData({
      ...newData
    });
    }
  };

  return (
    <>
      <div className="md-form mt-3 text-left col-md-12">
        <label className="containerBox">
          <input
            type="checkbox"
            name="invoice"
            onChange={handleCheckBox}
          />
          <span className="checkmark"></span>
          <span>Factura</span>
        </label>
      </div>
      {invoice ? (
        <div className="row">
          <div
            className={
              inputColumns
                ? `md-form mt-3 ${labelAlign} col-md-6`
                : `md-form mt-3 ${labelAlign} col-md-12`
            }
          >
            <label htmlFor="razonsocial">Razon Social:</label>
            <input
              type="text"
              name="razonsocial"
              onChange={handleChange}
              placeholder="Ingrese razon social"
              className="form-control"
              css={focusInput}
            />
            {errors.razonsocial && <p className="error">{errors.razonsocial}</p>}
          </div>

          <div
            className={
              inputColumns
                ? `md-form mt-3 ${labelAlign} col-md-6`
                : `md-form mt-3 ${labelAlign} col-md-12`
            }
          >
            <label htmlFor="rutfactura">RUT:</label>
            <input
              type="text"
              name="rutfactura"
              onChange={handleChange}
              placeholder="Ingrese RUT"
              className="form-control"
              css={focusInput}
            />
            {errors.rutfactura && <p className="error">{errors.rutfactura}</p>}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default InvoiceComponent;