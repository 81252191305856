/** @jsxRuntime classic */
/** @jsx jsx */
/**@jsxFrag React.Fragment */
import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { jsx } from "@emotion/core";
import { addDays } from "date-fns";
import es from "date-fns/locale/es";
import "./DateInput.css";

registerLocale("es", es);

const DateInput = ({ input, errors, focusInput, handleChangeDate }) => {
  const [startDate, setStartDate] = useState();

  const handleDate = (date) => {
    setStartDate(date);
    handleChangeDate(input.name, date);
  };

  return (
    <>
      <label htmlFor={input.name}>{input.label}</label>
      <DatePicker
        className="form-control"
        dateFormat="dd/MM/yyyy"
        locale="es"
        css={focusInput}
        selected={startDate}
        onChange={handleDate}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        maxDate={addDays(new Date(), -6570)}
        dropdownMode="select"
      />
      {errors[input.name] && <p className="error">{errors[input.name]}</p>}
    </>
  );
};

export default DateInput;
