import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import firebaseConfig from "./config";

class Firebase {
  constructor() {
    if(!app.apps.length) {
      app.initializeApp(firebaseConfig);
    }
    this.auth = app.auth();
    this.db = app.firestore();
  }

  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  current() {
    return this.auth.currentUser;
  }

  async logout() {
    await this.auth.signOut();
}
}

const firebase = new Firebase();

export default firebase;