import React from "react";
import CheckBoxComponent from "../checkBoxComponent/CheckBoxComponent";

const CheckBoxCreator = ({ inputs, handleCheckBox }) => {
  return (
    <>
      {inputs &&
        inputs.template.map((input, index) => (
          <div className="md-form mt-3" key={`${input.type}-${index}`}>
            {input.type === "checkbox" ? (
              <CheckBoxComponent
                input={input}
                handleCheckBox={handleCheckBox}
              />
            ) : null}
          </div>
        ))}
    </>
  );
};

export default CheckBoxCreator;
