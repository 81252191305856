/** @jsxRuntime classic */
/** @jsx jsx */
/**@jsxFrag React.Fragment */
import React from "react";
import { css, jsx } from "@emotion/core";

const CheckBoxComponent = ({ input, handleCheckBox }) => {
  return (
    <>
      <div className="md-form mt-3 text-left col-md-12">
        <label className="containerBox">
          <input
            type={input.type}
            name={input.name}
            value={input.value}
            onChange={handleCheckBox}
          />
          <span className="checkmark"></span>
          <span>{input.label}</span>
        </label>
      </div>
    </>
  );
};

export default CheckBoxComponent;
