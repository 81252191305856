import React from "react";
import './SelectComponent.css'

const SelectComponent = ({ input, errors, handleChange }) => {
  return (
    <>
      <label htmlFor={input.name}>{input.label}</label>
      <select
        name={input.name}
        className="form-control"
        onChange={handleChange}
      >
        <option value="">Seleccione</option>
        {input.options.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
      {errors[input.name] && <p className="error">{errors[input.name]}</p>}
    </>
  );
};

export default SelectComponent;
