import React from "react";
import TextAreaComponent from "../textAreaComponent/TextAreaComponent";
import './TextAreaCreator.css'

const TextAreaCreator = ({inputs,
  errors,
  handleChange,
  labelAlign,
  focusInput
}) => {
  return (
    <>
      {inputs &&
        inputs.template.map((input, index) => (
          <div className={`md-form mt-3 ${labelAlign}`} key={`${input.type}-${index}`}>
            {input.type === "textbox" ? (
              <TextAreaComponent
              input={input}
              handleChange={handleChange}
              errors={errors}
              focusInput={focusInput}
            />
            ) : null}
          </div>
        ))}
    </>
  );
};

export default TextAreaCreator;
