/** @jsxRuntime classic */
/** @jsx jsx */
/**@jsxFrag React.Fragment */
import React from "react";
import { jsx } from "@emotion/core";

const InfoPanel = ({
  urlBusinessLogo,
  logoBusinessEnableInfoPanel,
  title,
  panelText,
  headerTheme,
  media,
  headerEnablePanelInfo,
  cardImage,
  imageEnable,
  divSize,
  logoBusinessSize,
  htmlInfo,
}) => {
  return (
    <>
      {imageEnable ? (
        <div
          className="jumbotron jumbotron-fluid panelSize my-3 mx-auto"
          css={cardImage}
        >
          <div css={divSize}></div>
        </div>
      ) : (
        <div className="card my-3 panelSize">
          {logoBusinessEnableInfoPanel ? (
            <img src={urlBusinessLogo} alt="logo" css={logoBusinessSize} />
          ) : headerEnablePanelInfo ? (
            <h5 className="card-header text-center py-4" css={headerTheme}>
              {title}
            </h5>
          ) : null}
          <div className="card-body px-lg-5">
            {media && media.showImage ? (
              <img className="img-fluid" src={media.imageUrl} alt="Prueba" />
            ) : null}
            {media && media.showVideo ? (
              <div className="h_iframe">
                <iframe
                  title="video"
                  src={media.videoUrl}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            ) : null}
            <div>
              <div dangerouslySetInnerHTML={{ __html: htmlInfo }} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoPanel;
