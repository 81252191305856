/** @jsxRuntime classic */
/** @jsx jsx */
/**@jsxFrag React.Fragment */
import React from "react";
import { css, jsx } from "@emotion/core";
import "./SelectSubscription.css"

const SelectSubscription = ({
  inputs,
  errors,
  handleClick,
  handleChangeSubsription,
  handleChangeSubsriptionOther,
}) => {
  const { theme } = inputs || {};
  const { button } = theme || {};
  const { background, textColor, backgroundHover, textColorHover, shape, borderColor } =
    button || {};

  const { subscriptions } = inputs || {};

  const buttonTheme = css`
     {
      background: ${background};
      color: ${textColor};
      border: 0.1rem solid;
      border-color: ${borderColor};
      &:hover + label {
        background: ${backgroundHover};
        color: ${textColorHover};
        border-color: ${backgroundHover};
      }
      &:checked + label {
        background: ${backgroundHover};
        color: ${textColorHover};
        border-color: ${backgroundHover};
      }
    }
  `;

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {subscriptions &&
          subscriptions
            .sort(
              (subscriptionA, subscriptionB) =>
                subscriptionA.order - subscriptionB.order
            )
            .map((subscription, index) => (
              <li
                className="nav-item"
                role="presentation"
                key={`${subscription.type}-${index}`}
              >
                <a
                  className={index === 0 ? "nav-link active" : "nav-link"}
                  id={`${subscription.type}-tab`}
                  data-toggle="tab"
                  href={`#${subscription.type}`}
                  role="tab"
                  aria-controls={`${subscription.type}`}
                  aria-selected={index === 0 ? "true" : "false"}
                >
                  {subscription.type}
                </a>
              </li>
            ))}
      </ul>
      <div className="tab-content" id="myTabContent">
        {subscriptions &&
          subscriptions.map((subscription, index) => (
            <div
              className={
                index === 0 ? "tab-pane fade show active" : "tab-pane fade"
              }
              id={subscription.type}
              role="tabpanel"
              aria-labelledby={`${subscription.type}-tab`}
              key={`${subscription.type}-${index}`}
            >
              <ul className="ulSelected" onClick={() => handleClick(subscription.type)}>
                {subscription.costs.map((cost, index) => (
                  <li className="liSelected" key={`${cost}-${index}`}>
                    <input
                      className="inputHidden"
                      css={buttonTheme}
                      type="radio"
                      value={subscription.planId[index]}
                      name="planId"
                      id={`radio-${subscription.type}-${index}`}
                      onChange={handleChangeSubsription}
                    />
                    <label
                      className={shape}
                      css={buttonTheme}
                      htmlFor={`radio-${subscription.type}-${index}`}
                    >
                      {cost}
                    </label>
                  </li>
                ))}
                {inputs.otherEnable ? (
                  <li className="liSelected">
                    <input
                      className="inputHidden"
                      css={buttonTheme}
                      type="radio"
                      value={subscription.planIdOther}
                      name="planId"
                      id={`radio-otro-${index}`}
                      onChange={handleChangeSubsriptionOther}
                    />
                    <label
                      className={`${shape} subscriptioRadio`}
                      css={buttonTheme}
                      htmlFor={`radio-otro-${index}`}
                    >
                      Otro
                    </label>
                  </li>
                ) : null}
              </ul>
            </div>
          ))}
        {errors.subscriptionType && <p className="error">{errors.subscriptionType}</p>}
      </div>
    </>
  );
};

export default SelectSubscription;
