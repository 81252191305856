import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import firebase, { firebaseContext } from "./firebase";
// import { ToastProvider } from 'react-toast-notifications'
import Form from "./components/form/Form"
import "./App.css";
import Admin from "./components/admin/Admin";

function App() {
  const variable = require('md5')
  // const date = new Date()
  // const mesActual = date.toLocaleDateString();
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = dd + "/" + mm + "/" + yyyy + "bDY%deVxg#";
  // console.log(variable(today));
  // console.log(`${today}`);
  return (
    <firebaseContext.Provider value={{ firebase }}>
      <Router>
        <Routes>
          <Route path={"/" + variable(today)} element={<Admin />}/>
            {/* <ToastProvider> */}
            {/* <Admin /> */}
            {/* </ToastProvider> */}
          {/* </Route> */}
          <Route path="/:id" element={<Form />}/>
            {/* <Form /> */}
          {/* </Route> */}
        </Routes>
      </Router>
    </firebaseContext.Provider>
  );
}

export default App;
