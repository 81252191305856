import React, { useState, useEffect, useContext } from "react";
// import { useToasts } from 'react-toast-notifications'
import { firebaseContext } from "../../firebase";
import GeneralSection from "../generalSection/GeneralSection";
import MediaSection from "../mediaSection/MediaSection";
import SubscriptionSection from "../subscriptionSection/SubscriptionSection";
import TemplateSection from "../templateSection/TemplateSection";
import ThemeSection from "../themeSection/ThemeSection";
import Error403 from "../error403/Error403";
import axios from "axios";
import "./Admin.css";

function Admin() {
  // const { addToast } = useToasts()

  const [subscription, setSubscription] = useState({
    itemID: "",
    infoPanelEnable: false,
    template: [],
    media: {
      imageUrl: "",
      showImage: false,
      showVideo: false,
      videoUrl: "",
    },
    theme: {
      backgroundBody: "#ffffff",
      button: {
        backgroundHover: "#ffffff",
        textColorHover: "#ffffff",
        borderColor: "#ffffff",
        textColor: "#ffffff",
        shape: "pills-btn ",
        submitText: "",
        background: "#ffffff",
      },
      inputColumns: true,
      swapColumns: false,
      header: {
        logoPaykuEnable: false,
        logoBusinessEnableForm: false,
        logoBusinessEnableInfoPanel: false,
        logoBusinessWidth: "",
        logoBusinessHeigth: "",
        textColorHeader: "#ffffff",
        backgroundHeader: "#ffffff",
        urlBusinessLogo: "",
      },
      labelAlign: "text-left",
      inputTheme: {
        shadowBoxR: "255",
        iBorderColorFocus: "#ffffff",
        shadowBoxG: "255",
        shadowBoxB: "255",
        iBorderColor: "#ffffff",
      },
    },
    subscriptions: [],
    headerEnablePanelInfo: false,
    headerEnableForm: false,
    limitMount: {
      maxMount: 0,
      minMount: 0,
    },
    businessName: "",
    cardBackground: {
      imageEnable: false,
      imageUrl: "",
      imageWidth: "",
      imageHeight: "",
    },
    panelText: "",
    htmlInfo: "",
    title: "",
    paykuUrl: "https://app.payku.cl/${urlPart}/index?",
    otherEnable: false,
  });

  const [forms, setForms] = useState();
  const [activeForm, setActiveForm] = useState("crear");
  const { firebase } = useContext(firebaseContext);
  const [validation, setValidation] = useState("INVALID");

  async function login() {
    await firebase
      .login(process.env.REACT_APP_USER, process.env.REACT_APP_PASS)
      .catch((err) => {});
  }

  useEffect(() => {
    const getForms = () => {
      firebase.db
        .collection("forms")
        .get()
        .then(function (querySnapshot) {
          const listForms = [];
          querySnapshot.forEach(function (doc) {
            listForms.push({ docId: doc.id, name: doc.data().businessName });
          });
          setForms(listForms);
        });
    };

    window.addEventListener("message", (e) => {
      let url = "";
      let token = "";

      const testing = window.location.search;
      const urlParams = new URLSearchParams(testing);
      const origin = urlParams.get("origin");
      const a = urlParams.get("a");
      const s = urlParams.get("s");

      if (origin?.indexOf("prd") > -1) {
      // if (e.origin.indexOf("app") > -1) {
        url = process.env.REACT_APP_URL;
        token = process.env.REACT_APP_TOKEN;
      } else {
        url = process.env.REACT_APP_URL_DES;
        token = process.env.REACT_APP_TOKEN_DES;
      }

      axios
        .post(
          `${url}/api/validadmin`,
          {
            user: a,
            session: s,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          getForms();
          setValidation(response.data.status);
        })
        .catch(function (error) {
          // console.log(error);
        });
      window.parent.postMessage(true, url);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getForm = () => {
      if (activeForm === "crear") {
        setSubscription({
          itemID: "",
          infoPanelEnable: false,
          template: [],
          media: {
            imageUrl: "",
            showImage: false,
            showVideo: false,
            videoUrl: "",
          },
          theme: {
            backgroundBody: "#ffffff",
            button: {
              backgroundHover: "#ffffff",
              textColorHover: "#ffffff",
              borderColor: "#ffffff",
              textColor: "#ffffff",
              shape: "pills-btn ",
              submitText: "",
              background: "#ffffff",
            },
            inputColumns: true,
            swapColumns: false,
            header: {
              logoPaykuEnable: false,
              logoBusinessEnableForm: false,
              logoBusinessEnableInfoPanel: false,
              logoBusinessWidth: "",
              logoBusinessHeigth: "",
              textColorHeader: "#ffffff",
              backgroundHeader: "#ffffff",
              urlBusinessLogo: "",
            },
            labelAlign: "text-left",
            inputTheme: {
              shadowBoxR: "255",
              iBorderColorFocus: "#ffffff",
              shadowBoxG: "255",
              shadowBoxB: "255",
              iBorderColor: "#ffffff",
            },
          },
          subscriptions: [],
          headerEnablePanelInfo: false,
          headerEnableForm: false,
          limitMount: {
            maxMount: 0,
            minMount: 0,
          },
          businessName: "",
          cardBackground: {
            imageEnable: false,
            imageUrl: "",
            imageWidth: "",
            imageHeight: "",
          },
          panelText: "",
          htmlInfo: "",
          title: "",
          paykuUrl: "https://app.payku.cl/${urlPart}/index?",
          otherEnable: false,
        });
      } else {
        firebase.db.collection("forms").onSnapshot(handleData);
      }
    };
    getForm();
    // eslint-disable-next-line
  }, [activeForm]);

  function handleData(snapshot) {
    const forms = snapshot.docs.filter((doc) => doc.id === activeForm);
    setSubscription(forms[0].data());
  }

  const { template, media, theme, subscriptions } = subscription || {};

  const handleChangeTemplate = (e) => {
    if (
      ["label", "name", "order", "type", "options", "require"].includes(
        e.target.dataset.name
      )
    ) {
      let newTemplate = [...template];
      if (e.target.dataset.name === "options") {
        newTemplate[e.target.dataset.id][e.target.dataset.name] =
          e.target.value.split(",");
      } else if (e.target.dataset.name === "require") {
        newTemplate[e.target.dataset.id][e.target.dataset.name] =
          e.target.value === "true" ? true : false;
      } else {
        newTemplate[e.target.dataset.id][e.target.dataset.name] = parseInt(
          e.target.value
        )
          ? parseInt(e.target.value)
          : e.target.value;
      }
      setSubscription({
        ...subscription,
        template: newTemplate,
      });
    }
  };

  const addInput = () => {
    let newTemplate = [
      ...template,
      {
        label: "",
        name: "",
        order: "",
        type: "text",
        options: "",
        require: true,
      },
    ];
    setSubscription({
      ...subscription,
      template: newTemplate,
    });
  };

  const deleteInput = (index) => {
    let newTemplate = [...template];
    newTemplate.splice(index, 1);
    setSubscription({
      ...subscription,
      template: newTemplate,
    });
  };

  const handleChangeSubscription = (e, index, costIndex = 0) => {
    let newSubscription = [...subscriptions];
    if (["order", "type", "planIdOther"].includes(e.target.dataset.name)) {
      newSubscription[index][e.target.dataset.name] = parseInt(e.target.value)
        ? parseInt(e.target.value)
        : e.target.value;
    }
    if (["costs", "planId"].includes(e.target.dataset.name)) {
      let newValue = { ...newSubscription[index] };
      newValue[e.target.dataset.name][costIndex] = e.target.value;
      newSubscription[index] = newValue;
    }
    setSubscription({
      ...subscription,
      subscriptions: newSubscription,
    });
  };

  const addPlan = (index) => {
    let newSubscription = [...subscriptions];
    let newPlan = { ...newSubscription[index] };
    newPlan.costs.push("");
    newPlan.planId.push("");
    newSubscription[index] = newPlan;
    setSubscription({
      ...subscription,
      subscriptions: newSubscription,
    });
  };

  const deletePlan = (index, costIndex) => {
    let newSubscription = [...subscriptions];
    let newPlan = { ...newSubscription[index] };
    newPlan.costs.splice(costIndex, 1);
    newPlan.planId.splice(costIndex, 1);
    newSubscription[index] = newPlan;
    setSubscription({
      ...subscription,
      subscriptions: newSubscription,
    });
  };

  const deleteSubscription = (index) => {
    let newSubscription = [...subscriptions];
    newSubscription.splice(index, 1);
    setSubscription({
      ...subscription,
      subscriptions: newSubscription,
    });
  };

  const addSubscription = () => {
    let newSubscription = [
      ...subscriptions,
      {
        costs: [],
        order: 1,
        planId: [],
        planIdOther: "",
        type: "",
      },
    ];
    setSubscription({
      ...subscription,
      subscriptions: newSubscription,
    });
  };

  const handleChangeTheme = (e) => {
    let newTheme = { ...theme };
    if (["button", "header", "inputTheme"].includes(e.target.dataset.name)) {
      newTheme[e.target.dataset.name][e.target.name] =
        e.target.value === "true"
          ? true
          : e.target.value === "false"
          ? false
          : e.target.value;
    } else {
      newTheme[e.target.name] =
        e.target.value === "true"
          ? true
          : e.target.value === "false"
          ? false
          : e.target.value;
    }
    setSubscription({
      ...subscription,
      theme: newTheme,
    });
  };

  const handleChangeMedia = (e) => {
    let newMedia = { ...media };
    newMedia[e.target.name] =
      e.target.value === "true"
        ? true
        : e.target.value === "false"
        ? false
        : e.target.value;
    setSubscription({
      ...subscription,
      media: newMedia,
    });
  };

  const handleChangeGeneral = (e) => {
    let newSubscription = { ...subscription };
    if (["limitMount"].includes(e.target.dataset.name)) {
      newSubscription[e.target.dataset.name][e.target.name] = parseInt(
        e.target.value
      );
    } else {
      newSubscription[e.target.name] =
        e.target.value === "true"
          ? true
          : e.target.value === "false"
          ? false
          : e.target.value;
    }
    setSubscription(newSubscription);
  };

  const handleActiveForm = (e) => {
    setActiveForm(e.target.value);
  };

  const handleUpdate = () => {
    login();
    // let msg = ""
    if (activeForm === "crear") {
      firebase.db
        .collection("forms")
        .doc(`${subscription.itemID}`)
        .set(subscription);
      setForms([
        ...forms,
        { docId: subscription.itemID, name: subscription.businessName },
      ]);
      setActiveForm(subscription.itemID);
      // msg = "Se creó el formulario exitosamente."
    } else {
      firebase.db
        .collection("forms")
        .doc(`${subscription.itemID}`)
        .update(subscription);
      // msg = "Se actualizó el formulario exitosamente."
    }
    // addToast(msg, { appearance: 'success', autoDismiss: true, });
  };

  return (
    <div className="App">
      {validation === "VALID" ? (
        <>
          <GeneralSection
            activeForm={activeForm}
            forms={forms}
            handleActiveForm={handleActiveForm}
            subscription={subscription}
            handleChangeGeneral={handleChangeGeneral}
            handleUpdate={handleUpdate}
          />
          <TemplateSection
            template={template}
            handleChangeTemplate={handleChangeTemplate}
            addInput={addInput}
            deleteInput={deleteInput}
          />
          <SubscriptionSection
            subscriptions={subscriptions}
            handleChangeSubscription={handleChangeSubscription}
            addPlan={addPlan}
            deletePlan={deletePlan}
            deleteSubscription={deleteSubscription}
            addSubscription={addSubscription}
          />
          <ThemeSection theme={theme} handleChangeTheme={handleChangeTheme} />
          <MediaSection media={media} handleChangeMedia={handleChangeMedia} />
        </>
      ) : (
        <Error403 />
      )}
    </div>
  );
}

export default Admin;