import React from 'react';
import './ThemeSection.css'

const ThemeSection = ({theme, handleChangeTheme}) => {

  const {inputColumns, swapColumns, labelAlign, backgroundBody, button, inputTheme, header } = theme || {};
  const {background, backgroundHover, borderColor, textColor, textColorHover, submitText, shape} = button || {};
  const {iBorderColor, iBorderColorFocus} = inputTheme || {};
  const {backgroundHeader, textColorHeader, logoBusinessEnableInfoPanel, logoBusinessEnableForm, logoPaykuEnable, urlBusinessLogo, logoBusinessHeigth, logoBusinessWidth } = header || {};
  return (
    <>
      <div className="card-body-payku">
        <div className="header-payku">
          <h1 className="tittle-form-sub">Configuración de Tema</h1>
        </div>
        <div className="section-theme">
          <div className="header-payku">
            <h3 className="tittle-form-sub-sub">General</h3>
          </div>
          <div className="theme-general">
            <div className="theme-item">
              <label htmlFor="inputColumns"># de Columnas</label>
              <select
                name="inputColumns"
                value={inputColumns}
                onChange={handleChangeTheme}
                data-name="general"
              >
                <option value={false}>Una Columna</option>
                <option value={true}>Dos Columnas</option>
              </select>
            </div>

            <div className="theme-item">
              <label htmlFor="swapColumns">Intercambio de Paneles</label>
              <select
                name="swapColumns"
                value={swapColumns}
                onChange={handleChangeTheme}
                data-name="general"
              >
                <option value={false}>No</option>
                <option value={true}>Si</option>
              </select>
            </div>

            <div className="theme-item">
              <label htmlFor="labelAlign">Alineación de Label</label>
              <select
                name="labelAlign"
                value={labelAlign}
                onChange={handleChangeTheme}
                data-name="general"
              >
                <option value="text-center">Centrada</option>
                <option value="text-right">Derecha</option>
                <option value="text-left">Izquierda</option>
              </select>
            </div>

            <div className="theme-item">
              <label htmlFor="backgroundBody">Color de Fondo</label>
              <input
                type="color"
                value={backgroundBody}
                name="backgroundBody"
                onChange={handleChangeTheme}
                data-name="general"
              />
            </div>
          </div>
        </div>

        <div className="section-theme">
          <div className="header-payku">
            <h3 className="tittle-form-sub-sub">Botones</h3>
          </div>
          <div className="theme-button">
            <div className="theme-item">
              <label htmlFor="background">Color</label>
              <input
                type="color"
                value={background}
                name="background"
                onChange={handleChangeTheme}
                data-name="button"
              />
            </div>

            <div className="theme-item">
              <label htmlFor="backgroundHover">Color Hover</label>
              <input
                type="color"
                value={backgroundHover}
                name="backgroundHover"
                onChange={handleChangeTheme}
                data-name="button"
              />
            </div>

            <div className="theme-item">
              <label htmlFor="borderColor">Color de Bordes</label>
              <input
                type="color"
                value={borderColor}
                name="borderColor"
                onChange={handleChangeTheme}
                data-name="button"
              />
            </div>

            <div className="theme-item">
              <label htmlFor="textColor">Color de Texto</label>
              <input
                type="color"
                value={textColor}
                name="textColor"
                onChange={handleChangeTheme}
                data-name="button"
              />
            </div>

            <div className="theme-item">
              <label htmlFor="textColorHover">Color de Texto Hover</label>
              <input
                type="color"
                value={textColorHover}
                name="textColorHover"
                onChange={handleChangeTheme}
                data-name="button"
              />
            </div>

            <div className="theme-item">
              <label htmlFor="iBorderColor">Color Input Border</label>
              <input
                type="color"
                value={iBorderColor}
                name="iBorderColor"
                onChange={handleChangeTheme}
                data-name="inputTheme"
              />
            </div>

            <div className="theme-item">
              <label htmlFor="iBorderColorFocus">
                Color Input Border Focus
              </label>
              <input
                type="color"
                value={iBorderColorFocus}
                name="iBorderColorFocus"
                onChange={handleChangeTheme}
                data-name="inputTheme"
              />
            </div>

            <div className="theme-item">
              <label htmlFor="submitText">Texto del Botón</label>
              <input
                type="text"
                value={submitText}
                name="submitText"
                placeholder="Texto del botón"
                onChange={handleChangeTheme}
                data-name="button"
              />
            </div>

            <div className="theme-item">
              <label htmlFor="shape">Forma de Botones</label>
              <select
                name="shape"
                value={shape}
                onChange={handleChangeTheme}
                data-name="button"
              >
                <option value="pills-btn">Píldora</option>
                <option value="circle-btn">Circular</option>
                <option value="oval-btn">Ovalado</option>
                <option value="rounded-btn">Redondeado</option>
                <option value="square-btn">Rectangular</option>
              </select>
            </div>
          </div>
        </div>

        <div className="section-theme">
          <div className="header-payku">
            <h3 className="tittle-form-sub-sub">Encabezado</h3>
          </div>
          <div className="theme-header">
            <div className="theme-item">
              <label htmlFor="backgroundHeader">Color</label>
              <input
                type="color"
                value={backgroundHeader}
                name="backgroundHeader"
                onChange={handleChangeTheme}
                data-name="header"
              />
            </div>
            <div className="theme-item">
              <label htmlFor="textColorHeader">Color del Texto</label>
              <input
                type="color"
                value={textColorHeader}
                name="textColorHeader"
                onChange={handleChangeTheme}
                data-name="header"
              />
            </div>

            <div className="theme-item">
              <label htmlFor="logoBusinessEnableInfoPanel">Logo en Panel</label>
              <select
                name="logoBusinessEnableInfoPanel"
                value={logoBusinessEnableInfoPanel}
                onChange={handleChangeTheme}
                data-name="header"
              >
                <option value={false}>No visible</option>
                <option value={true}>Visible</option>
              </select>
            </div>

            <div className="theme-item">
              <label htmlFor="logoBusinessEnableForm">Logo en Formulario</label>
              <select
                name="logoBusinessEnableForm"
                value={logoBusinessEnableForm}
                onChange={handleChangeTheme}
                data-name="header"
              >
                <option value={false}>No visible</option>
                <option value={true}>Visible</option>
              </select>
            </div>

            <div className="theme-item">
              <label htmlFor="logoPaykuEnable">Logo Payku</label>
              <select
                name="logoPaykuEnable"
                value={logoPaykuEnable}
                onChange={handleChangeTheme}
                data-name="header"
              >
                <option value={false}>No visible</option>
                <option value={true}>Visible</option>
              </select>
            </div>

            <div className="theme-item">
              <label htmlFor="urlBusinessLogo">URL Logo Empresa</label>
              <input
                type="text"
                value={urlBusinessLogo}
                name="urlBusinessLogo"
                placeholder="URL logo de la empresa"
                onChange={handleChangeTheme}
                data-name="header"
              />
            </div>

            <div className="theme-item">
              <label htmlFor="logoBusinessHeigth">Logo Empresa Altura</label>
              <input
                type="text"
                value={logoBusinessHeigth}
                name="logoBusinessHeigth"
                placeholder="Altura del logo"
                onChange={handleChangeTheme}
                data-name="header"
              />
            </div>

            <div className="theme-item">
              <label htmlFor="logoBusinessWidth">Logo Empresa Ancho</label>
              <input
                type="text"
                value={logoBusinessWidth}
                name="logoBusinessWidth"
                placeholder="Ancho del logo"
                onChange={handleChangeTheme}
                data-name="header"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ThemeSection;