import React from 'react';
import './Error403.css'

const Error403 = () => {
  return ( 
    <> 
    <div className="error-container">
      <div className="card-payku-error">
        <div className="card-body-payku-error">
          <h1 className="title-error">Acceso Prohibido</h1>
          <h3 className="text-error">Código de Error 403</h3>
        </div>
      </div>
    </div>
    </>
   );
}
 
export default Error403;