import React from 'react';
import './SubscriptionSection.css'

const SubscriptionSection = ({subscriptions, handleChangeSubscription, addPlan, deletePlan, deleteSubscription, addSubscription}) => {

  return (
    // <div className="card-payku">
    <div className="card-body-payku">
      <div className="header-payku">
        <h1 className="tittle-form-sub">Planes de Suscripción</h1>
      </div>
      {subscriptions &&
        subscriptions.map((suscription, index) => (
          <>
            <div className="plan-section" key={index}>
              <div className="header-payku">
                <h1 className="tittle-form-sub-sub">Suscripción {index + 1}</h1>
              </div>
              <div className="plan-info">
                <div className="plan-item">
                  <label htmlFor={`position-${index}`}>{`Posición`}</label>
                  <input
                    type="text"
                    name={`position-${index}`}
                    data-id={index}
                    id={`position-${index}`}
                    data-name="order"
                    value={suscription.order}
                    onChange={(e) => handleChangeSubscription(e, index)}
                  />
                </div>
                <div className="plan-item">
                  <label
                    htmlFor={`type-${index}`}
                  >{`Tipo de Suscripción`}</label>
                  <input
                    type="text"
                    name={`type-${index}`}
                    placeholder="Tipo de Suscripción"
                    data-id={index}
                    id={`type-${index}`}
                    data-name="type"
                    value={suscription.type}
                    onChange={(e) => handleChangeSubscription(e, index)}
                  />
                </div>
                <div className="plan-item">
                  <label
                    htmlFor={`planIdOther-${index}`}
                  >{`Id de Plan variable`}</label>
                  <input
                    type="text"
                    name={`planIdOther-${index}`}
                    placeholder="Id de Plan variable"
                    data-id={index}
                    id={`planIdOther-${index}`}
                    data-name="planIdOther"
                    value={suscription.planIdOther}
                    onChange={(e) => handleChangeSubscription(e, index)}
                  />
                </div>
              </div>
              <div className="plan-suscription">
                {suscription.costs.map((cost, costIndex) => (
                  <div className="card-body-payku-sub">
                    <div className="header-payku-sub">
                      <h1 className="tittle-form-sub-sub2">
                        Plan {costIndex + 1}
                      </h1>
                        <button
                          className="btn-danger-payku-sub"
                          onClick={() => deletePlan(index, costIndex)}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                    </div>
                    <div className="plan-group" key={`plan-${costIndex}`}>
                      <div className="plan-item plan-item-subscription">
                        <label
                          htmlFor={`costs-${index}-${costIndex}`}
                        >{`Monto`}</label>
                        <input
                          type="text"
                          name={`cost-${index}-${costIndex}`}
                          placeholder="Monto"
                          id={`cost-${index}-${costIndex}`}
                          value={cost}
                          onChange={(e) =>
                            handleChangeSubscription(e, index, costIndex)
                          }
                          data-name="costs"
                        />
                      </div>
                      <div className="plan-item plan-item-subscription">
                        <label
                          htmlFor={`planId-${index}-${costIndex}`}
                        >{`Id Plan`}</label>
                        <input
                          type="text"
                          name={`planId-${index}-${costIndex}`}
                          placeholder="Id del plan"
                          data-id={costIndex}
                          id={`planId-${index}-${costIndex}`}
                          value={suscription.planId[costIndex]}
                          onChange={(e) =>
                            handleChangeSubscription(e, index, costIndex)
                          }
                          data-name="planId"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="btn-section">
                <button
                  className="btn-delete-payku"
                  onClick={() => deleteSubscription(index)}
                >
                  Eliminar Suscripción
                </button>
                <button
                  className="btn-payku btn-primary-payku"
                  onClick={() => addPlan(index)}
                >
                  Agregar plan
                </button>
              </div>
            </div>
          </>
        ))}
      <div className="container-button">
        <button
          className="btn-payku btn-primary-payku"
          onClick={addSubscription}
        >
          Agregar Suscripción
        </button>
      </div>
    </div>
    // </div>
  );
}

export default SubscriptionSection;