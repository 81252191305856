import React from 'react';
import './MediaSection.css'

const MediaSection = ({ media, handleChangeMedia }) => {

  const {showImage, imageUrl , showVideo, videoUrl } = media || {}

  return (
    <>
      {/* <div className="card-payku"> */}
      <div className="card-body-payku">
        <div className="header-payku">
          <h1 className="tittle-form-sub">Media</h1>
        </div>
        <div className="theme-general">
          <div className="media-item">
            <label htmlFor="showImage">Mostrar Imagen</label>
            <select
              name="showImage"
              value={showImage}
              onChange={handleChangeMedia}
            >
              <option value={false}>No</option>
              <option value={true}>Si</option>
            </select>
          </div>

          <div className="media-item">
            <label htmlFor="imageUrl">URL Imagen</label>
            <input
              type="text"
              value={imageUrl}
              name="imageUrl"
              placeholder="Url de la imagen"
              onChange={handleChangeMedia}
            />
          </div>

          <div className="media-item">
            <label htmlFor="showVideo">Mostrar Video</label>
            <select
              name="showVideo"
              value={showVideo}
              onChange={handleChangeMedia}
            >
              <option value={false}>No</option>
              <option value={true}>Si</option>
            </select>
          </div>

          <div className="media-item">
            <label htmlFor="videoUrl">URL Video</label>
            <input
              type="text"
              value={videoUrl}
              name="videoUrl"
              placeholder="Url del video"
              onChange={handleChangeMedia}
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default MediaSection;