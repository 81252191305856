import React from 'react';
import './GenearlSection.css'

const GeneralSection = ({
  subscription,
  handleChangeGeneral,
  forms,
  handleActiveForm,
  activeForm,
  handleUpdate,
}) => {
  const {
    itemID,
    businessName,
    title,
    panelText,
    headerEnableForm,
    headerEnablePanelInfo,
    infoPanelEnable,
    otherEnable,
    limitMount,
    paykuUrl,
    htmlInfo,
    invoice,
  } = subscription || {};
  const { minMount, maxMount } = limitMount || {};

  return (
    <>
      {/* <div className="card-payku"> */}
      <div className="card-body-payku">
        <div className="header-payku">
          <h1 className="tittle-form-sub">Configuración General</h1>
          <div className="btn-section-admin">
            {activeForm === "crear" ? null : (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://suscripciones.payku.cl/${subscription.itemID}`}
              >
                <button
                  className="btn-primary-payku-view"
                  onClick={handleUpdate}
                >
                  <i className="fas fa-eye" />
                </button>
              </a>
            )}
            <button
              className="btn-primary-payku-updated"
              onClick={handleUpdate}
            >
              {activeForm === "crear"
                ? "Crear Formulario"
                : "Actualizar Formulario"}
            </button>
          </div>
          <select
            name="activeForm"
            onChange={handleActiveForm}
            value={activeForm}
            className="activeForm"
          >
            <option value="crear">Crear Formulario</option>
            {forms &&
              forms.map((form) => (
                <option key={form.docId} value={form.docId}>
                  {form.name}
                </option>
              ))}
          </select>
        </div>
        <div className="theme-general">
          <div className="general-item">
            <label htmlFor="itemID">
              ID <span>Oblig</span>
            </label>
            <input
              type="text"
              value={itemID}
              disabled={itemID ? true : false}
              name="itemID"
              placeholder="Coloca el ID"
              onChange={handleChangeGeneral}
            />
          </div>

          <div className="general-item">
            <label htmlFor="businessName">
              Empresa <span>Oblig</span>
            </label>
            <input
              type="text"
              value={businessName}
              name="businessName"
              placeholder="Empresa"
              onChange={handleChangeGeneral}
            />
          </div>

          <div className="general-item">
            <label htmlFor="title">
              Título <span>Oblig</span>
            </label>
            <input
              type="text"
              value={title}
              name="title"
              placeholder="Título"
              onChange={handleChangeGeneral}
            />
          </div>

          <div className="general-item">
            <label htmlFor="panelText">
              Texto del Panel <span>Oblig</span>
            </label>
            <input
              type="text"
              value={panelText}
              name="panelText"
              placeholder="Texto del panel"
              onChange={handleChangeGeneral}
            />
          </div>

          <div className="general-item">
            <label htmlFor="headerEnableForm">Encabezado Formulario</label>
            <select
              name="headerEnableForm"
              value={headerEnableForm}
              onChange={handleChangeGeneral}
            >
              <option value={false}>No visible</option>
              <option value={true}>Visible</option>
            </select>
          </div>

          <div className="general-item">
            <label htmlFor="headerEnablePanelInfo">Encabezado Panel</label>
            <select
              name="headerEnablePanelInfo"
              value={headerEnablePanelInfo}
              onChange={handleChangeGeneral}
            >
              <option value={false}>No visible</option>
              <option value={true}>Visible</option>
            </select>
          </div>

          <div className="general-item">
            <label htmlFor="infoPanelEnable">Panel</label>
            <select
              name="infoPanelEnable"
              value={infoPanelEnable}
              onChange={handleChangeGeneral}
            >
              <option value={false}>No visible</option>
              <option value={true}>Visible</option>
            </select>
          </div>

          <div className="general-item">
            <label htmlFor="otherEnable">Otro Monto</label>
            <select
              name="otherEnable"
              value={otherEnable}
              onChange={handleChangeGeneral}
            >
              <option value={false}>No habilitado</option>
              <option value={true}>Habilitado</option>
            </select>
          </div>

          <div className="general-item">
            <label htmlFor="minMount">
              Monto Min <span>Oblig</span>
            </label>
            <input
              type="text"
              value={minMount}
              name="minMount"
              placeholder="Monto mínimo"
              data-name="limitMount"
              onChange={handleChangeGeneral}
            />
          </div>

          <div className="general-item">
            <label htmlFor="maxMount">
              Monto Max <span>Oblig</span>
            </label>
            <input
              type="text"
              value={maxMount}
              name="maxMount"
              placeholder="Monto máxmino"
              data-name="limitMount"
              onChange={handleChangeGeneral}
            />
          </div>

          <div className="general-item">
            <label htmlFor="paykuUrl">Payku URL</label>
            <input
              type="text"
              value={paykuUrl}
              name="paykuUrl"
              onChange={handleChangeGeneral}
            />
          </div>

          <div className="general-item">
            <label htmlFor="invoice">Facturación</label>
            <select
              name="invoice"
              value={invoice}
              onChange={handleChangeGeneral}
            >
              <option value={false}>No habilitado</option>
              <option value={true}>Habilitado</option>
            </select>
          </div>

          <div className="general-item">
            <label htmlFor="htmlInfo">html Info</label>
            <input
              type="text"
              value={htmlInfo}
              name="htmlInfo"
              placeholder="HTML info"
              onChange={handleChangeGeneral}
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default GeneralSection;